import Swiper, {
  Navigation, Lazy, EffectFade, Pagination, Autoplay,
} from 'swiper';
import { waitDOMReady } from '../../../common/common.helpers';

Swiper.use([Navigation, Lazy, Pagination, EffectFade, Autoplay]);

class IndexSlider {
  constructor() {
    this.sliderClass = '.index-top-slider-container';

    this.init();
  }

  init() {
    waitDOMReady(this.sliderClass, 10000)
      .then((container) => {
        const swiper = new Swiper(this.sliderClass, {
          preloadImages: false,
          lazy: {
            loadOnTransitionStart: true,
          },
          autoplay: {
            delay: 7000,
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          loop: true,
          slidesPerView: 1,
          pagination: {
            el: '.index-top-slider-container-pagination',
            clickable: true,
            bulletClass: 'index-top-slider-container-pagination__bullet',
            bulletActiveClass: 'index-top-slider-container-pagination__bullet--active',
          },
          navigation: {
            nextEl: '.index-top-slider-container__btn-next',
            prevEl: '.index-top-slider-container__btn-prev',
          },
        });

        // eslint-disable-next-line no-unused-vars
        swiper.on('lazyImageLoad', (instance, slide) => {
          const image = slide.querySelector('img');
          image.setAttribute('alt', image.dataset.alt);
          delete image.dataset.alt;
        });

        container.addEventListener('mouseenter', (e) => {
          swiper.autoplay.stop();
          e.currentTarget.addEventListener('mouseleave', () => {
            swiper.autoplay.start();
          }, { once: true });
        });
      });
  }
}

const indexSlider = () => {
  // eslint-disable-next-line no-new
  new IndexSlider();
};

export default indexSlider;
