const waitDOMReady = (container, timeLimit = 3000) => {
		return new Promise((resolve, reject) => {
				if (document.querySelector(container)) {
						resolve(document.querySelector(container));
				}
				const time = (+new Date());
				const readyInterval = setInterval(() => {
						const containerNode = document.querySelector(container);
						if (!containerNode) {
								if (timeLimit && (new Date() - time) >= timeLimit) {
										reject(new Error('Таймаут ожидания DOM истёк.'));
								} else {
										return;
								}
						}
						clearInterval(readyInterval);
						resolve(containerNode);
				}, 20);
		});
};

export {
		waitDOMReady
}