import Swiper, {
  Lazy, EffectFade, Pagination, Autoplay,
} from 'swiper';

Swiper.use([Lazy, Pagination, EffectFade, Autoplay]);

const indexBanners = () => {
  const swiper = new Swiper('#indexPageBanners', {
    preloadImages: false,
    // Enable lazy loading
    lazy: {
      loadOnTransitionStart: true,
    },
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loop: true,
    slidesPerView: 1,
    pagination: {
      el: '.index-banners-pagination',
      clickable: true,
      type: 'progressbar',
    },
    navigation: {
      nextEl: '.index-banners-btn-next',
      prevEl: '.index-banners-btn-prev',
    },
    breakpoints: {
      1200: {
        pagination: {
          type: 'bullets',
        },
      },
    },
  });

  swiper.on('lazyImageLoad', (instance, slide) => {
    const image = slide.querySelector('[data-alt]');
    image.setAttribute('alt', image.dataset.alt);
    delete image.dataset.alt;
  });
};

export default indexBanners;
